import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from '../components/Layout'
import Information from '../components/Information'
import SEOComponent from '../components/SEOComponent'


const StyledImgDiv = styled.div`
  max-width: 800px;
`;
const StyledInfoDiv = styled.div`
  p {
    font-size: 2rem;
    line-height: 1.2em;
    text-decoration: none;
    padding-top: 1rem;
    /* No greater than 479px, no less than 280px */
    @media (max-width:479px) and (min-width:280px) {
      font-size: 1.1em;
    }​
  }
  padding-bottom: 1.67rem;
  max-width: 800px;
`;

export default function Info({ data }) {
  return (
    <>
      <SEOComponent title={`Artist & engineer - Matt Oliver`}> </SEOComponent>
      <Layout>
        <Information>
          <StyledInfoDiv>
            <p className="info-text">Artist and <a href="https://bigorangerecording.com">engineer</a>, based in Texas.</p>
            <p className="info-text">Very large variety of creative work.</p>
            <p className="info-text">Currently working in product & engineering.</p>
            <p className="info-text">Please feel free to get in touch.</p>
          </StyledInfoDiv>
          {/*<hr className="nav-rule"/>*/}
          {/* <StyledImgDiv>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Matt Oliver performs on stage at the Fonda theatre in Los Angeles."
            />
          </StyledImgDiv> */}


        </Information>
      </Layout>
    </>
  )
}

// export const data = graphql` {
//     file(relativePath: {eq: "matt-guitar.jpg"}) {
//       childImageSharp {
//         fluid{
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `



