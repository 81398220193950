import React from "react"
import styled from "styled-components"

const StyledInformation = styled.div`
  padding-left: 1.33em;
  @media (max-width: 375px) {
    padding-left: .67em;
  }
`;

export default function Information({ data, children }) {
  return (
    <StyledInformation>
    	<div className="hero">
        <hr className="nav-rule" />			
   			{children}
      </div>
    </StyledInformation>
  );

}

